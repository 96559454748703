@import "../../ui/media.scss";

.document-upload {
  margin: 20px 0 0 0;
  padding: 0 0 20px 0;
  // border-bottom: 1px solid var(--color-fieldset-border);

  @include tablet {
    margin: 0 0 20px 0;
  }

  &:last-child {
    border-bottom: 0;
    padding: 0;
  }
}

.doc-upload-button-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 10px;

  & > * {
    flex: 1 0 250px;
  }
}

.doc-upload {
  position: relative;

  margin-top: 15px;

  input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  .progress {
    background-color: var(--color-bg);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 5px;
    border-radius: 5px;
    opacity: 0;
    transition: opacity ease 300ms;
    pointer-events: none;
  }

  &.is-pending {
    .progress {
      opacity: 1;
    }
  }

  .doc-upload-view-link {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .error-box {
    background-color: var(--color-red-light);
    color: var(--color-red-heavy);

    padding: 10px;

    margin-bottom: 15px;
    border-radius: 5px;
    line-height: 1.5;

    font-weight: 500;
  }
}
