@import "../../ui/media.scss";

.or {
  position: relative;

  .or-divider {
    border-top: 1px solid var(--color-disabled);
    height: 0px;
    text-align: center;
    display: block;
    line-height: 1;
    width: 100%;
    margin: 24px 0;

    @include tablet {
      margin: 34px 0;
    }

    i {
      position: relative;
      background-color: inherit;
      top: -10px;
      padding: 0 12px;
      background-color: var(--color-bg);
    }
  }

  &.white-bg {
    .or-divider {
      i {
        background-color: var(--color-white);
      }
    }
  }
}
