.cashless-promotion {
  .cashless-text {
    display: flex;
    svg {
      margin-right: 10px;

      color: var(--color-success);
    }

    p {
      line-height: 1.5;

      // align-items: center;
    }
  }
}
