.card-page-layout {
  padding: 0 20px;

  .top-logo-wrapper {
    margin: 20px 0;
  }

  max-width: 1024px;
  margin: 0 auto;

  .child-wrapper {
    max-width: 600px;
    // padding: 0 24px;
    margin: 0 auto;
  }
}
