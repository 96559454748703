@import "../../../ui/media.scss";

// :root {
//   --actions-height: 60px;
// }

$list-item-height: 50px;
$list-padding: 24px;
$list-padding-tablet: 50px;

.contract-actions {
  transition: opacity 300ms ease;
  opacity: 0;
  pointer-events: none;

  .additional-signature-info {
    display: none;
    position: relative;

    .icon {
      fill: var(--color-white);
      position: absolute;
      top: 3px;
      left: 5px;
      width: 20px;
      height: 20px;
    }
  }

  &.has-signature {
    .additional-signature-info {
      background-color: #4974d0;
      display: block;
      margin: 0 16px 0 60px;
      color: var(--color-white);
      font-weight: 500;
      padding: 4px 10px 4px 34px;
      border-radius: 14px;
      line-height: 1.3;
      // height: 50px;
      min-height: 26px;
    }
  }

  // .signature-last {
  //   background-color: var(--color-delete-heavy);
  //   background-color: #4974d0;
  //   color: var(--color-white);
  //   padding: 0 6px 0 25px;
  //   border-radius: 5px;
  //   display: flex;
  //   position: absolute;
  //   top: 40px;
  //   left: 60px;
  //   white-space: normal;
  //   text-align: left;
  //   height: 34px;
  //   // line-height: 20px;

  //   .signature-last-icon {
  //     position: absolute;
  //     width: 28px;
  //     height: 28px;
  //     width: 34px;
  //     height: 34px;
  //     padding: 5px;
  //     background-color: #4974d0;
  //     border-radius: 14px;
  //     left: -12px;
  //     top: 0px;
  //   }

  //   .icon {
  //     fill: var(--color-white);
  //     width: 18px;
  //     height: 18px;
  //   }

  // @include landscape {
  //   bottom: auto;
  //   left: calc(100% + 10px);
  //   top: 0;
  // }
  // }

  &.visible {
    opacity: 1;
    pointer-events: all;
  }

  .contract-actions-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 90;
    opacity: 0;
    pointer-events: none;
    background-color: var(--color-pure-black);
    transition: opacity 300ms ease;
    transform: translate3d(0, 0, 0);
  }

  .contract-actions-list {
    padding: 19px 16px 16px 16px;
    height: #{20px + $list-item-height};
    background-color: #f7f7f7;
    margin: 0 0 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .button {
      flex: 0 0 auto;
      height: 38px;
      width: 38px;
      padding: 10px;
    }

    @include landscape {
      padding: 20px 16px 15px 16px;
    }
  }

  .contract-actions-wrapper {
    position: fixed;
    bottom: 100px;
    right: 10px;
    z-index: 910;
    background-color: var(--link);
    max-width: 480px;

    @include desktop-mid {
      bottom: 300px;
      right: calc(
        50vw - 587px
      ); // 50vw - 512px - 25px (margin to cards) - 50px (width of button)
    }
  }

  .actions-toggle-button-bg {
    width: 50px;
    height: 50px;
    background-color: var(--link-hovered);
    opacity: 0;
    position: fixed;
    bottom: 100px;
    right: 10px;
    z-index: 900;
    border-radius: 50%;
    transition: opacity 800ms ease 800ms;

    @include desktop-mid {
      bottom: 300px;
      right: calc(
        50vw - 587px
      ); // 50vw - 512px - 25px (margin to cards) - 50px (width of button)
    }
  }

  &.visible {
    .actions-toggle-button-bg {
      opacity: 0.6;
      animation-duration: 700ms;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      animation-name: none;
    }

    &.show-pulse {
      .actions-toggle-button-bg {
        animation-name: pulse;
      }
    }
  }

  .contract-actions-wrapper {
    border-radius: 25px;
    width: 50px;
    height: 50px;
    overflow: hidden;
    box-shadow: 0 4px 16px -7px var(--link-shadow);
    transition: all 300ms ease;
    padding: 0 0 $list-padding 0;

    @include tablet {
      padding: 0 0 $list-padding-tablet 0;
    }

    &:hover,
    &:active,
    &:focus {
      box-shadow: 0 4px 16px -7px transparent;
    }

    ul {
      transition: opacity 300ms ease;
      pointer-events: none;
      opacity: 0;
    }

    svg {
      width: 30px;
      height: 30px;
      display: block;
      fill: var(--link);
    }

    button {
      display: flex;
      padding: 10px;
      height: $list-item-height;
      width: 100%;
      align-items: center;
      white-space: nowrap;
      position: relative;
      border-bottom: 1px solid var(--link);
      font-weight: 500;

      @include landscape {
        padding: 16px;
      }

      svg {
        margin: 0 20px 0 0;
      }

      &.action {
        border-bottom: 2px solid var(--link);
      }
    }

    .actions-toggle-button {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 50px;
      height: 50px;
      // background-color: var(--link);
      padding: 10px;
      border-radius: 25px;
      transition: opacity 300ms ease;
      min-width: 0;
      background-color: var(--color-white);
      color: var(--color-button-ghost);
      border: 2px solid var(--color-button-ghost);
      z-index: 1001;

      svg {
        margin: 0;
        // fill: var(--color-white);
        width: 30px;
        height: 30px;
      }
    }

    .beacon {
      position: absolute;
      left: 30px;
      top: 2px;
      pointer-events: none;
    }
  }

  li:last-child {
    button {
      border-bottom: 0;
    }
  }

  &.open {
    .contract-actions-bg {
      opacity: 0.6;
      pointer-events: all;
    }

    .actions-toggle-button {
      opacity: 0;
      pointer-events: none;
    }

    .contract-actions-wrapper {
      width: calc(100vw - 20px);
      background-color: var(--color-white);
    }

    ul {
      opacity: 1;
      pointer-events: all;
    }

    &.items-1 {
      .contract-actions-wrapper {
        height: #{$list-item-height + $list-padding * 2 + 1 * $list-item-height};
      }
    }

    &.items-2 {
      .contract-actions-wrapper {
        height: #{$list-item-height + $list-padding * 2 + 2 * $list-item-height};
      }
    }

    &.items-3 {
      .contract-actions-wrapper {
        height: #{$list-item-height + $list-padding * 2 + 3 * $list-item-height};
      }
    }

    &.items-4 {
      .contract-actions-wrapper {
        height: #{$list-item-height + $list-padding * 2 + 4 * $list-item-height};
      }
    }

    &.items-5 {
      .contract-actions-wrapper {
        height: #{$list-item-height + $list-padding * 2 + 5 * $list-item-height};
      }
    }

    &.items-6 {
      .contract-actions-wrapper {
        height: #{$list-item-height + $list-padding * 2 + 6 * $list-item-height};
      }
    }
  }
}

.CZ .contract-actions-wrapper {
  svg {
    fill: var(--color-black);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0.6;
  }

  100% {
    transform: scale(1.4);
    opacity: 0.1;
  }

  // 100% {
  //   transform: scale(1);
  //   opacity: 0.6;
  // }
}
