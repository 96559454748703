.contract-data {
  b {
    display: block;
  }

  .company-information {
    margin: 0 0 30px 0;

    dd,
    dt {
      // margin: 0 0 4px 0;
    }

    dd {
      overflow: visible;
      white-space: unset;
    }
  }
}
