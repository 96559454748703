@import "../../ui/media.scss";

.contract-additional-docs {
  .checkbox-button {
    height: auto;
    min-height: 38px;
    line-height: 36px;

    @include tablet {
      height: var(--height-interaction-tablet);
    }
  }

  .checkbox-label {
    white-space: normal;
    line-height: 1.5;
    padding: 7px 0;
    // font-size: 1.4rem;

    @include tablet {
      font-size: 1.6rem;
    }
  }

  strong {
    padding-left: 20px;
  }

  ul {
    list-style: inherit;
    margin-top: 10px;
    margin-bottom: 20px;
    padding-left: 16px;
  }

  li {
    padding-bottom: 4px;
    padding-left: 4px;
  }
}
