.pdf-viewer {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;

  iframe {
    flex: 1;
    width: 100%;
    height: 100%;
    border: none;
  }

  .loading-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 1;

    .slow-warning {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 400px;
      text-align: center;
      padding: 12px;
      font-weight: 500;
    }
  }
}
