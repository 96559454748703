.root {
  margin-bottom: 25px;
  border-radius: 10px;
  overflow: hidden;
}

.header {
  // background-color: rgb(203 229 225);
}

.content {
  border-radius: 10px;
  overflow: hidden;
  background-color: rgb(203 229 225);
}

.cards {
  padding: var(--p-content);
  // margin-top: 20px;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  padding: var(--p-content);
  background-color: rgb(203 229 225);
}
