@import "../../ui/media.scss";

.card-wrapper {
  .card-header {
    font-weight: 300;
    margin: 0 0 8px 16px;

    @include landscape {
      margin: 0 0 12px 23px;
    }
  }
}

.new-card-wrapper {
  box-shadow: 0px 18px 36px -18px rgba(0, 0, 0, 0.1), 0px 30px 45px -30px rgba(50, 50, 93, 0.25);
  border-radius: 0;
  position: relative;
  overflow: hidden;

  .new-card-header {
    background-color: var(--link);
    color: var(--color-white);
    font-weight: var(--font-weight-medium);
    line-height: normal;

    border-radius: 0;
    padding: 1em var(--p-content);

    fill: var(--color-white);

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
  }

  .header-incomplete {
    background: rgb(224, 148, 54);
  }

  @include tablet {
    border-radius: var(--border-radius-content);
  }
}

.card {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border: 2px solid var(--color-card-bg);
  border-radius: 10px;
  position: relative;
}

.card,
.new-card {
  background-color: var(--color-card-bg);
  padding: var(--p-content);

  & > div {
    position: relative;
    z-index: 10;
  }

  &.has-background {
    overflow: hidden;

    .card-bg {
      display: block;
    }
  }

  &.no-padding {
    padding: 0;
  }

  .card-bg {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 8;
    display: none;

    path {
      fill: var(--color-card-details);
      fill-opacity: 0.2;
    }

    svg {
      width: 100%;
      height: 100%;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }

  .max-content {
    max-width: calc($tablet - 100px);
    margin: 0 auto;
  }
}

.new-card {
  .card-bg {
    svg {
      border-top-right-radius: 0;
    }
  }
}
