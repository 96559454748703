@import "../../../../../ui/media.scss";

.root {
  margin-top: 2em;
}

.terminals {
  padding-top: 5px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;

  @include tablet {
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }
}
