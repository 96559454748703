@import "../../ui/media.scss";

.radio {
  position: relative;
  height: 20px;
  width: 20px;
  display: inline-block;
  border-radius: 50%;
  overflow: hidden;

  border: 2px solid var(--color-silver);

  @include tablet {
    height: 20px;
    width: 20px;
  }

  .radio-background {
    height: 100%;
    width: 100%;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .radio-background-checked {
    background-color: var(--color-link);
  }

  .radio-indicator {
    scale: 0.98;
    height: inherit;
    width: inherit;
    background-color: transparent;
    border-radius: 50%;
    transition: scale 0.2s ease-in-out;
  }

  .radio-indicator-checked {
    scale: 0.4;
    background-color: var(--color-white);
  }

  .radio-checked,
  .radio-unchecked {
    position: absolute;
    top: 0;
    left: 0;
    transition: transform 300ms ease;
    transform-origin: center;
    stroke: var(--color-link);
    fill: var(--color-bg);
  }

  .radio-checked {
    transform: scale(0);
    stroke-width: 3px;
    fill: var(--color-link);
    stroke: var(--color-bg);
  }

  &.checked {
    border: none;

    .radio-checked {
      transform: scale(1);
    }

    .radio-unchecked {
      transform: scale(0);
    }
  }
}

.radio-buttons {
  &.is-disabled {
    .radio-unchecked {
      stroke: var(--color-progress-border);
    }

    .radio-checked {
      fill: var(--color-progress-border);
    }
  }
}
