@import "../../../../ui/media.scss";

$box-border-radius: 10px;

.boxes {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;

  @include desktop {
    grid-template-columns: repeat(3, 1fr);
  }
}

.box {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 10px;

  padding: 16px;
  border-radius: $box-border-radius;
  background-color: white;
  // box-shadow: rgba(145, 158, 171, 0.22) 0px 0px 10px;
  border: 1px solid var(--color-link);
}

.box_line {
  position: absolute;
  height: 100%;
  width: 10px;
  right: 0;
  top: 0;
  border-radius: 0 $box-border-radius $box-border-radius 0;
}

.box_line[data-type="1"] {
  background-color: var(--link-hovered);
}

.box_line[data-type="2"] {
  background-color: var(--color-wl-turquoise);
}

.box_line[data-type="3"] {
  background-color: var(--color-wl-blue);
}

.text {
  max-width: 300px;
  font-size: var(--font-size-small);
  margin-bottom: 10px;
}

.blue {
  color: var(--color-wl-blue);

  svg {
    stroke: var(--color-wl-blue) !important;
  }
}

.turquoise {
  color: var(--color-wl-turquoise);
  svg {
    stroke: var(--color-wl-turquoise) !important;
  }
}
