@import "../../ui/media.scss";

.sheet {
  --sheet-spacing: 20px;
  --sheet-header-height: 48px;

  @include tablet {
    --sheet-header-height: 102px;
  }

  .sheet-container {
    height: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 999;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
  }

  &.ReactModal__Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
    display: flex;
    justify-content: center;
    z-index: 1500;
  }

  .ReactModal__Content {
    inset: unset !important;
    bottom: 0 !important;
    overflow: visible;
    max-width: 700px;
  }

  .sheet-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    z-index: 0;
    background-color: var(--color-ghost);
    pointer-events: none;
  }

  .sheet-body {
    position: relative;
    overflow: visible;
    max-height: calc(
      100% - (var(--sheet-header-height) - var(--sheet-spacing))
    );
    width: 100%;
    background: var(--color-white);
    bottom: 0;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    pointer-events: none;
    box-shadow: 0 -4px 12px 2px rgba(0, 0, 0, 0.1);

    &:after {
      position: absolute;
      content: "";
      bottom: -250px;
      height: 250px;
      width: 100%;
      background-color: var(--color-white);
    }

    &.full-height {
      min-height: calc(
        100% - (var(--sheet-header-height) - var(--sheet-spacing))
      );

      display: flex;
      flex-direction: column;

      .sheet-content {
        flex: 1;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
      }
    }

    .sheet-content {
      padding: 0 var(--sheet-spacing) var(--sheet-spacing) var(--sheet-spacing);
      margin-top: calc(var(--sheet-spacing) * 2);
      pointer-events: all;
      overflow: auto;
      height: calc(100% - (var(--sheet-spacing) * 2));
    }

    .sheet-content-no-padding {
      padding: 0;
    }

    .sheet-handle {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 36px;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      pointer-events: fill;
      z-index: 999;
      user-select: none;
      -webkit-tap-highlight-color: transparent;
      background-color: var(--color-white);

      .sheet-handle__bar {
        width: 40px;
        height: 4px;
        background: var(--color-ghost);
        border-radius: 2px;
        margin-top: 8px;
      }

      .sheet-handle__close {
        position: absolute;
        top: 0;
        right: 0;
        padding: 12px;

        .icon {
          bottom: 0;
          cursor: pointer;
          background-color: rgba(0, 0, 0, 0.075);
          border-radius: 100px;
          padding: 4px;
          width: 24px;
          height: 24px;

          line {
            stroke: var(--color-ghost);
            stroke-width: 3px;
          }
        }
      }
    }
  }
}
