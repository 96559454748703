.image-checkmark-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  overflow: hidden;

  &.animated {
    & > div {
      transition: transform 300ms ease;
      transform: translate(50px, -50px);
    }

    &.active {
      & > div {
        transform: translate(0, 0);
      }
    }
  }

  .triangle {
    background-color: var(--color-green);
    background-color: var(--link-hovered);
    transform-origin: top left;
    width: 80px;
    height: 80px;
    transform: rotate(-45deg);
    z-index: 1;
    position: relative;
  }

  .icon {
    position: absolute;
    top: 4px;
    right: 4px;
    z-index: 2;
    width: 22px;
    height: 22px;
    fill: white;
  }
}
