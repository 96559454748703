.selfie-upload-phone {
  .video-face-overlay {
    box-shadow: 0px 0px 0px 5000px rgba(255, 255, 255, 0.3);
    border: 8px solid rgba(255, 255, 255, 0.7);
    overflow: hidden;
    position: absolute;
    top: 20%;
    height: 50%;
    width: 60%;
    left: 20%;
    border-radius: 50vw;
  }
}

.selfie-confirm-phone {
  .columns {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
}

.selfie-upload-phone-buttons {
  flex: 1;
}
