@import "./media.scss";

.center {
  text-align: center;
}

.fw-700 {
  font-weight: 700;
}

.fw-600 {
  font-weight: 600;
}

.fw-500 {
  font-weight: 500;
}

.fw-400 {
  font-weight: 400;
}

.text-small {
  font-size: var(--font-size-small);
  line-height: var(--line-height-small);
}

.text-regular-plus {
  font-size: var(--font-size-regular-plus);
}

.text-large {
  font-size: var(--font-size-large);
}

.text-large-plus {
  font-size: var(--font-size-large-plus);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;

  & + p {
    margin: 0 0 5px 0;
  }
}

p {
  margin: 0;
  color: var(--color-text);
}

h1 {
  font-size: var(--font-size-title-plus);
  font-weight: 400;
  color: var(--color-text);

  letter-spacing: -0.05rem;
}

h2 {
  font-size: var(--font-size-title);
  font-weight: 400;
  color: var(--color-text);

  letter-spacing: -0.05rem;
}

h3 {
  font-size: var(--font-size-large);
  color: var(--color-text);
  font-weight: 600;
}

h4 {
  font-size: var(--font-size-regular-plus);
  color: var(--color-text);
  font-weight: 600;
}

h5 {
  font-size: var(--font-size-regular);
  color: var(--color-text);
  margin: 0 0 5px 0;
  font-weight: 600;
}

h6 {
  font-size: var(--font-size-small);
  color: var(--color-text);
  font-weight: 600;
}

b {
  color: var(--color-text);
  font-weight: 700;
}

strong {
  font-weight: 600;
}

blockquote,
i {
  font-style: normal;
}

.ellipsis {
  overflow: hidden;
  text-overflow: clip;
  text-overflow: ellipsis;
  white-space: nowrap;
}

figure,
blockquote {
  margin: 0;
  line-height: 1.5;
}

figcaption {
  cite {
    font-style: normal;
    font-size: 1.4rem;
    color: var(--color-sub-text);

    @include tablet {
      font-size: 1.6rem;
    }
  }
}

sup {
  vertical-align: top;
}

.text-right {
  text-align: right;
}
