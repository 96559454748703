@import "../../../ui/media.scss";

.root {
  display: flex;
  flex-direction: column;
  gap: 25px;

  & > *:not(:last-of-type) {
    padding-bottom: 25px;
    border-bottom: 1px solid var(--color-snow-heavy);
  }
}

.authfees_surcharges {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;

  & > * {
    flex: 1 1 325px;
  }

  margin-bottom: 20px;
}

.installationFee {
  display: flex;
  justify-content: space-between;
}

.cashback {
  font-weight: bold;
  display: flex;
  justify-content: space-between;
}
