.lang-selector {
  li {
    display: inline-block;
    margin: 0 10px 0 0;
    opacity: 0.5;

    &:not(.selected) {
      .flag {
        box-shadow: 0 0 8px -3px var(--color-slate);
      }
    }

    &.selected {
      opacity: 1;
    }

    &:last-child {
      margin: 0;
    }
  }
}
