@import "../../../ui/media.scss";

.pricing-cz {
  .icon-checkmark {
    fill: var(--color-link);
    height: 1.8rem;
    margin-bottom: -5px;
    margin-left: 2px;
  }

  ul {
    list-style: inherit;
    padding: 0 0 0 16px;

    @include landscape {
      padding: 0 0 0 25px;
    }
  }

  .pricing-table-cz {
    padding: 12px;
    border: 1px solid var(--color-link);
    background-color: var(--color-white);
    margin-top: 6px;

    .table-title {
      font-weight: bold;
      margin-bottom: 12px;
      border-bottom: 1px solid var(--color-link);
      padding: 0 12px 12px;
      text-align: center;

      @include tablet {
        text-align: left;
      }
    }

    .boxes {
      display: flex;
      flex-direction: column;
      margin-top: 12px;
      gap: 44px;
      border-radius: 6px;

      @include tablet {
        flex-direction: row;
      }

      .logos {
        display: flex;
        justify-content: space-around;
        margin-bottom: -24px;
        align-items: center;

        @include tablet {
          border-right: 1px solid var(--color-link);
          flex-direction: column;
          margin-right: -24px;
          margin-bottom: 0;
          padding: 0 12px;
        }

        .provider-logo {
          width: 36px;
        }
      }

      .box {
        position: relative;
        display: flex;
        flex-direction: column;
        flex: 1;
        align-items: center;
        text-align: center;
        padding: 12px;
        border-radius: 6px;

        &:after {
          position: absolute;
          content: "+";
          font-size: 2em;
          font-weight: 500;
          color: var(--color-link);
          bottom: -1.8em;

          left: calc(50% - 0.5em);

          @include tablet {
            left: unset;
            right: -1.1em;
            top: calc(50% - 1em);
          }
        }

        &:last-child:after {
          display: none;
        }

        .title {
          font-weight: 700;
        }

        .content {
          max-width: 250px;
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          font-size: 1.2rem;

          .fee {
            font-size: 2rem;
            font-weight: 600;
            opacity: 0.5;

            @include tablet {
              margin-top: 50%;
            }
          }

          p {
            font-size: 1.2rem;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}
