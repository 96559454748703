@import "../../../ui/media.scss";

.signature-modal {
  // Modal transition
  &.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 250ms ease-in-out;
  }

  &.ReactModal__Overlay--after-open {
    opacity: 1;
  }

  &.ReactModal__Overlay--before-close {
    opacity: 0;
    transition: opacity 150ms 100ms ease-in-out;
  }

  .ReactModal__Content {
    transform: scale(1.05);
    transition: transform 250ms ease-in-out;
  }

  .ReactModal__Content--after-open {
    transform: scale(1);
  }

  .ReactModal__Content--before-close {
    transform: scale(0.95);
  }

  // Modal default styling
  &.ReactModal__Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    z-index: 999;
    justify-content: center;
    align-items: flex-end;
  }

  .ReactModal__Content {
    padding: 0;
    box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.9);
    max-height: 100%;
    margin: 10px;
    overflow-x: hidden;
    padding-bottom: 10px;
    outline: none;
    width: 100%;
  }

  .canvas {
    background-color: #fff;
    border-bottom: 2px solid rgba(0, 0, 0, 0.4);
  }

  .signature-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    max-width: 100%;
    overflow: hidden;
    flex-wrap: wrap;
    padding-bottom: 24px;
    width: 100%;
    background-color: #e4e4e4;
    .text-right {
      margin: 10px 0 0 0;
    }
  }

  .confirm-signature {
    margin: 20px 0 0 0;
  }

  .signature-name {
    font-size: 2.2rem;
    font-weight: 800;
  }

  .signature-buttons {
    & > div {
      @include tablet {
        max-width: 300px;
      }
    }

    .button {
      margin: 0 0 10px 0;
    }
  }
}
