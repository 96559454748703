.preview-page {
  padding: 50px 0 200px 0;
  min-height: 100vh;

  .retry-error {
    text-align: center;
  }

  .already-confirmed,
  .contract-complete,
  &.missing-id {
    padding: 50px 0;

    .logo {
      max-width: 225px;
      margin: 0 0 30px 0;
    }
  }

  .status-box {
    margin: 40px 0;
  }
}
