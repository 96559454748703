.document-upload-phone,
.document-confirm-phone,
.selfie-upload-phone,
.selfie-confirm-phone {
  h1.absolute-header {
    position: fixed;
    top: 10%;
    left: 50%;
    transform: translate(-50%, 0);
    font-weight: bold;
    color: var(--color-white);
  }
}

.document-upload-phone,
.selfie-upload-phone {
  video {
    object-fit: cover;
    height: 100vh;
  }

  .video-wrapper {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 100vh;
  }

  .video-canvas {
    display: none;
  }

  .button-wrapper {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: hsla(0, 0%, 0%, 0.8);
    height: 88px;
    align-items: center;
    padding: 16px;

    .button {
      height: 56px;
    }

    .empty-slot {
      width: 44px;
    }
  }
}

.document-confirm-phone,
.selfie-confirm-phone {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  height: 100vh;

  .height-anim {
    flex: 1 0 auto;
  }

  img {
    object-fit: cover;
    height: 100%;
  }

  .button-wrapper {
    display: flex;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: hsla(0, 0%, 0%, 0.8);
    min-height: 88px;
    align-items: center;
    padding: 16px;

    .empty-slot {
      width: 38px;
    }

    .camera-upload-progress {
      position: absolute;
      height: 5px;
      top: 0;
      left: 0;
      background-color: var(--color-link);
      transition: width 300ms ease;
    }
  }

  .button + .button {
    margin: 0 0 0 30px;
  }

  .icon-pending {
    span {
      background-color: rgb(19, 204, 99);
      background-color: var(--color-link-hover);
    }
  }

  .status-box.error-box {
    color: var(--color-white);

    .status-box-icon {
      background-color: var(--color-red-heavy);
    }

    .icon-error-outline {
      fill: var(--color-red-light);
    }
  }
}
