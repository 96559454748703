.portal-login {
  padding: 0 0 20px 0;
  margin: 0 0 20px 0;
  border-bottom: 1px solid var(--color-fieldset-border);

  &:last-child {
    border-bottom: 0;
    padding: 0;
    margin: 0;
  }
}
