.root {
  font-weight: 500;
}

.header {
  background-color: rgb(203 229 225);
}

.cashless_header {
  font-style: italic;
  margin-bottom: 10px;
  padding: calc(var(--p-content) / 2) var(--p-content);
}

.labels {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  font-size: 0.9em;
  gap: 20px;
  color: var(--color-text-passive);

  & > * {
    padding: calc(var(--p-content) / 2) var(--p-content);
  }
}

.step {
  position: relative;
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  background-color: var(--link-alt);
  gap: 20px;

  &:not(:last-of-type) {
    border-bottom: 1px solid rgb(209, 226, 224);
  }
}

.fees,
.threshold {
  padding: calc(var(--p-content) / 2) var(--p-content);
}
