.root {
  border-radius: var(--border-radius-content);
  overflow-x: auto;
  font-weight: 500;
  background-color: var(--color-snow);
  margin-bottom: 25px;
  background-color: #e2e9f7;
}

.header {
  padding: var(--p-content);
  border-bottom: 1px solid #c0cbdf;
}

.storePomca {
  display: grid;
  grid-template-columns: 1fr 1.75fr;
  gap: 20px;

  padding: 20px var(--p-content);

  border-bottom: 1px solid #c0cbdf;

  dt {
    // font-size: var(--font-size-small);
  }

  dd {
    text-align: right;
    margin: 0 !important;
  }
}

.footer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  background-color: #e2e9f7;
  align-items: center;

  padding: var(--p-content);
}

.fee {
  font-weight: 600;
  text-align: right;
}
