@import "../../../../ui/media.scss";

.bank-statement-upload {
  position: relative;

  .beacon {
    display: inline-block;
    margin: 0 4px 0 0;
    position: relative;
    top: 1px;

    @include tablet {
      top: 3px;
      margin: 0 6px 0 0;
    }
  }

  .bank-statement-upload-filename {
    margin: 10px 0 0 0;
    display: flex;
    align-content: center;

    span {
      max-width: 200px;
      display: inline-block;
      margin: 0 10px 0 0;

      @include tablet {
        margin: 0 20px 0 0;
        max-width: 300px;
      }
    }
  }

  .bank-statement-upload-done {
    .button {
      width: 100%;
    }
  }

  input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  .progress {
    background-color: var(--color-bg);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 5px;
    border-radius: 5px;
    opacity: 0;
    transition: opacity ease 300ms;
    pointer-events: none;
  }

  &.is-pending {
    .progress {
      opacity: 1;
    }
  }

  .error-box {
    background-color: var(--color-red-light);
    color: var(--color-red-heavy);

    padding: 10px;

    margin-bottom: 15px;
    border-radius: 5px;
    line-height: 1.5;

    font-weight: 500;
  }

  // .bank-statement-upload-done {
  //   padding: 40px 0 0 0;
  // }
}
