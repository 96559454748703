.progress {
  .progress-pending-bar-bg {
    height: 4px;
    background-color: var(--color-progress-bar-bg);
    border-radius: 2px;
    position: relative;
    overflow: hidden;
  }

  .progress-pending-bar-overlay {
    height: 4px;
    background-color: var(--color-link);
    transition: transform 300ms ease;
  }
}
