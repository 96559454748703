@import "../../ui/media.scss";

$nameWidth: 130px;
$nameWidthLarger: 180px;

.contract {
  max-width: $wide;
  margin: 0 auto;

  .section-wrapper {
    padding: 0 var(--p-content);
  }

  .section-subtitle {
    font-weight: 600;
    // color: #004a4b;
    // font-size: 19px;
    margin-bottom: 10px;
    display: block;
  }

  .contract-info {
    .card,
    .new-card {
      position: relative;
      overflow: initial;
    }
  }

  .split {
    div {
      margin-top: 2em;
    }

    dt,
    dd {
      margin: 0;
      padding: 0;
      min-width: 0;
      @include truncate;
    }

    dt {
      flex: 0 0 $nameWidth;
      padding: 0 14px 0 0;
      width: $nameWidth;
    }

    dd {
      flex: 1 0 auto;
      width: calc(100% - #{$nameWidth});
    }

    dl {
      display: flex;
      flex-wrap: wrap;
    }

    &.split-larger {
      dt {
        flex: 0 0 $nameWidthLarger;
      }

      dd {
        width: calc(100% - #{$nameWidthLarger});
      }
    }
  }

  dt,
  dd {
    margin-bottom: 0;
    padding: 0;
    min-width: 0;
    display: block;
  }

  dt {
    font-weight: 500;
    color: var(--color-label);
  }

  dd {
    font-weight: 500;
    color: var(--color-text);
    margin: 0 0 18px 0;
  }

  dl {
    margin: 0;
  }

  .bank-account,
  .signees,
  .portal-logins,
  .contract-data,
  .contract-package,
  .contract-advanced-pricing,
  .contract-multiple-stores,
  .owners,
  .contract-preview {
    // margin: 0 0 70px 0;

    // @include tablet {
    //   margin: 0 0 100px 0;
    // }
  }

  .owners + .contract-additional-docs {
    margin: 70px 0 0 0;

    @include tablet {
      margin: 100px 0 0 0;
    }
  }

  // .identify,
  // .documents-card,
  // .add-bank-account,
  // .signatures {
  //   margin: 0 0 90px 0;

  //   @include tablet {
  //     margin: 0 0 110px 0;
  //   }
  // }

  .upload {
    margin: 0 0 40px 0;
  }

  .already-confirmed-warning {
    padding: 0 0 30px 0;
  }

  .merchant-todo-section {
    display: flex;
    flex-direction: column;
    margin-top: var(--gap-section);
    gap: var(--gap-section);
  }
}

.signee,
.owner {
  padding: 0 0 30px 0;
  margin: 0 0 20px 0;
  border-bottom: 1px solid var(--color-fieldset-border);

  &:last-child {
    border-bottom: 0;
    padding: 0;
    margin: 0;
  }

  b {
    display: block;
    margin: 20px 0 5px 0;
  }
}

.view-button {
  margin: 30px 0 50px 0;

  .ghost {
    display: block;
    width: 100%;

    @include tablet {
      display: inline-flex;
      width: auto;
    }
  }
}
