.confirm-button {
  .visible-component {
    display: flex;
    align-items: center;
    white-space: nowrap;
  }

  .button {
    transition: all 300ms ease 300ms;
    position: relative;
    z-index: 10;

    &.no-anim {
      transition: none;
    }

    &.confirmed {
      opacity: 0;
      pointer-events: none;
    }
  }
}
