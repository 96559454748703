@import "../../../../ui/media.scss";

.root {
  display: flex;
}

.image_wrapper {
  display: flex;
  align-items: center;

  & > * {
    width: 40px;

    @include tablet {
      width: 45px;
    }
  }
}

.data {
  flex: 1;
  padding: 10px 0;

  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-between;

  margin-left: 30px;

  @include tablet {
    margin-left: 20px;
  }
}

.terminal_name {
  font-weight: 500;
  line-height: var(--line-height-small);
  //   color: var(--color-text-passive);
}

.terminal_amount {
  font-weight: 700;
  font-size: 30px;
  line-height: normal;

  @include tablet {
    font-size: 40px;
  }

  //   color: var(--color-text-passive);
}
