@import "./ui/media.scss";

:root {
  --link: #4974d0;
  --link-hovered: #459fff;
  --link-shadow: #326aa7;
  --link-alt: #e5eef4;

  --color-success-text: #004a4b;
  --color-success: #007b7c;
  --color-success-light: #e5eef4;

  --color-green: #2eb56a;
  --color-green-light: #29eb7f;
  --color-green-heavy: #0f9f4f;

  --color-snow: #f5f6fa;
  --color-snow-light: #f9fafc;
  --color-snow-heavy: #e5e9f2;

  --color-pure-black: #000;
  --color-white: #fff;
  // --color-black: #1f2d3d;
  --color-black: #1b1c1e;
  --link: #007b7c;
  --link-hovered: #46beaa;
  --link-shadow: #284646;
  --color-white: #fff;
  --color-pending: #8492a6;
  --color-disabled: #8492a6;
  --color-silver: #8492a6;
  --color-fieldset-border: #c0ccda;
  --color-bg: #f5f6fa;
  --color-delete-light: #ffeff2;
  --color-delete-heavy: #fc607d;
  --color-done-light: #c9f2be;
  --color-done-heavy: #397129;
  --color-ghost: #646f80;
  --color-card-details: var(--link-hovered);

  --color-red: #df7676;
  --color-red-light: #f6eaec;
  --color-red-heavy: #c04647;
  --color-red-extra-heavy: #832122;

  --color-wl-yellow: #ffeb78;
  --color-wl-turquoise: #41b4d2;
  --color-wl-pink: #f08791;
  --color-wl-blue: #005a8c;

  --color-label: #6f7172;
  --color-text: var(--color-black);
  --color-text-passive: var(--color-ghost);
  --color-text-error: var(--color-red-heavy);
  --color-text-success: var(--color-green-heavy);
  --color-button-ghost: var(--link);
  --color-progress-bar-bg: var(--color-snow);
  --color-progress-border: var(--color-smoke-heavy);
  --color-link: var(--link);
  --color-link-hover: var(--link-hovered);

  --color-button: var(--link);
  --color-button-hovered: var(--link-hovered);

  --height-interaction-mini: 24px;
  --height-interaction-mini-tablet: 28px;
  --height-interaction-small: 30px;
  --height-interaction-small-tablet: 34px;
  --height-interaction: 38px;
  --height-interaction-tablet: 42px;

  --color-card-bg: var(--color-white);
  --color-input-border: var(--color-silver);
  --color-input-bg: var(--color-white);

  --color-delete-bg: var(--color-delete-light);
  --color-delete-text: var(--color-delete-heavy);

  --color-button-shadow: 0 4px 16px -7px var(--link-shadow);

  --p-content: 18px;
  --border-radius-content: 10px;

  --font-size-title-plus: 3.6rem;
  --font-size-title: 3.2rem;
  --font-size-large-plus: 2.8rem;
  --font-size-large: 2.4rem;
  --font-size-regular-plus: 2rem;
  --font-size-regular: 1.6rem;
  --font-size-small: 1.45rem;

  --line-height-default: 1.7;
  --line-height-small: 1.5;

  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;

  @include tablet {
    --p-content: 24px;
    --font-size-title-plus: 3.6rem;
    --font-size-title: 3.2rem;
    --font-size-large-plus: 2.8rem;
    --font-size-large: 2.4rem;
    --font-size-regular-plus: 2rem;
    --font-size-regular: 1.6rem;
    --font-size-small: 1.5rem;
  }

  --max-width-content: 600px;
  --gap-section: 60px;
}

.CZ {
  --link: #c03;
  --link-hovered: #f03564;
  --link-shadow: #720420;
  --color-link: var(--link);
  --color-link-hover: var(--link-hovered);
  --color-button-ghost: var(--link);
  --color-button: var(--link);
  --color-button-hovered: var(--link-hovered);
  --color-card-details: var(--color-black);
  --color-card-details: #b7b7b7;
  --color-card-details: #d1d6e9;
}

html {
  font-size: 62.5%;
  background-color: var(--color-bg);
}

body {
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: var(--font-size-regular);
  line-height: var(--line-height-default);
  font-weight: var(--font-weight-regular);
  color: var(--color-black);

  margin: 0;
}

// .CZ {
//   font-family: "Open Sans", sans-serif;

//   button {
//     font-family: "Open Sans", sans-serif;
//   }
// }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

article {
  max-width: $desktop;
  margin: 0 auto;
}

.center {
  text-align: center;
}

button {
  border: 0;
  padding: 0;
  outline: none;
  background-color: transparent;
  font-size: inherit;
  cursor: pointer;
  font-family: "Inter", sans-serif;
  color: var(--color-button);
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

// .success-box,
// .error-box {
//   background-color: var(--color-delete-light);
//   color: var(--color-delete-heavy);
//   font-weight: 500;
//   border-radius: 8px;
//   padding: 16px;
// }

// .success-box {
//   background-color: var(--color-done-light);
//   color: var(--color-done-heavy);
// }

a {
  color: var(--color-link);
  transition: color 300ms ease;

  &:hover,
  &:focus,
  &:active {
    color: var(--link-shadow);
  }
}

hr {
  border: 0;
  border-top: 1px solid var(--color-bg);
  margin: 49px 0 50px 0;
}

.fs-small {
  font-size: var(--font-size-small);
}

.color-green-heavy {
  color: var(--color-green-heavy);
}
