.icon_container {
  text-align: center;
  margin-bottom: 10px;
}

.success {
  color: var(--color-success);
  font-weight: 500;
  text-align: center;
}
