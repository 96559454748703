.root {
  //   background-color: var(--color-snow);
  border-radius: 10px;
  overflow: hidden;
  background-color: var(--color-snow);
  //   border: 1px solid rgb(209, 209, 209);
}

.header {
  padding: var(--p-content);
  border-bottom: 1px solid rgb(219, 219, 219);
}

.articles {
}

.article {
  display: grid;
  grid-template-columns: 1fr 1.75fr;
  gap: 20px;
  padding: 20px var(--p-content);

  &:not(:last-of-type) {
    border-bottom: 1px solid rgb(219, 219, 219);
  }

  dt {
    // font-size: var(--font-size-small);
    font-weight: 500;
    color: var(--color-label);
  }

  dd {
    margin: 0 !important;
    color: var(--color-black);
    text-align: right;
  }

  .free {
    color: var(--color-green-heavy);
  }
}
