@import "../../../ui/media.scss";

.root {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: var(--p-content);
  max-width: var(--max-width-content);
  margin: 0 auto;
  margin-bottom: 0;

  @include tablet {
    padding: 0;
    margin: 50px auto;
  }
}

.logo {
  width: 175px;
  height: auto;

  @include tablet {
    width: 250px;
  }
}
