.associate-icon {
    flex: 0 0 42px;
    height: 42px;
    width: 42px;
    border-radius: 21px;
    padding: 6px;
  
    .icon {
      width: 30px;
      height: 30px;
    }
  
    &.has-required-information {
      background-color: rgba(196, 247, 230, 1);
      .icon {
        fill: #13ae7a;
      }
    }
  
    &.has-missing-information {
      background-color: rgba(246, 234, 236, 1);
      .icon {
        fill: #c04647;
      }
    }
  
    &.has-partial-information {
      background-color: rgba(255, 246, 213, 1);
      .icon {
        fill: #e69900;
      }
    }
  }
  