@import "../../../ui/media.scss";

.root {
  padding: var(--p-content);
  max-width: var(--max-width-content);
  margin: 0 auto;

  @include tablet {
    padding: 0;
  }
}

.logo {
  margin-bottom: calc(var(--gap-section) * 1);
}
