@import "../../../ui/media.scss";

.confirm-page {
  padding: 0 0 100px 0;
  min-height: 100vh;

  .retry-error {
    text-align: center;
  }

  .already-confirmed,
  .contract-complete,
  &.missing-id {
    padding: 50px 0;

    .logo {
      max-width: 225px;
      margin: 0 0 30px 0;
    }
  }

  .intro {
    margin: 0 0 30px 0;
    @include tablet {
      margin: 0 0 50px 0;
    }

    .logo-wrapper {
      margin: 0 0 40px 0;

      @include tablet {
        margin: 0 0 80px 0;
      }
    }

    p {
      //max-width: 640px;
      line-height: 1.65;
    }
  }

  .confirm-intro,
  .sign-intro {
    margin: 0 auto;
    max-width: 600px;
  }

  .owners + .sales-contact {
    margin: 40px auto 50px auto;

    @include tablet {
      margin: 40px auto 80px auto;
    }
  }
}
