@import "../../../ui/media.scss";

.document-upload {
  .document-upload-status {
    background-color: var(--color-snow);

    border-radius: 10px;

    // padding: 0 0 5px 55px;
    padding: 16px;
    position: relative;

    .beacon {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }
}

.document-upload-view {
  padding: 0 0 0 55px;
  margin: 0 0 16px 0;
  position: relative;

  .beacon {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.root {
  padding: var(--p-content);
  border-radius: 10px;
  background-color: var(--color-snow);

  // border: 1px dashed var(--color-silver);
}

.uploaded {
  background-color: var(--color-success-light);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.title {
  font-size: 1.1em;
  font-weight: 600;
  margin: 0;
}

.title_uploaded {
  color: var(--color-success);
}

.description {
  line-height: 1.5;
}
