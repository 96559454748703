@import "../../../ui/media.scss";

.CZ .done-page {
  .all-done-box {
    color: #007b7c;
  }
}

.done-page {
  padding: 0 var(--p-content);

  .sales-contact-flex {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 8px 0;

    dt {
      flex: 0 0 100px;
      margin: 2px 0;
      font-size: inherit;
    }

    dd {
      flex: 0 0 calc(100% - 100px);
      margin: 2px 0;
    }
  }

  .all-done-box {
    max-width: 600px;
    margin: 100px auto 40px auto;
    padding: 16px 16px 20px 8px;
    background-color: #e5f2f4;
    vertical-align: top;
    color: var(--color-link);
    border-radius: 10px;
    display: flex;
    align-items: center;
    line-height: 1.5;

    @include landscape {
      padding: 16px 16px 20px 12px;
      margin: 100px auto 60px auto;
    }

    .beacon {
      margin: 0 8px 0 0;
      flex: 0 0 auto;
      align-self: flex-start;
      position: relative;
      top: 2px;

      @include tablet {
        margin: 0 16px 0 0;
      }
    }

    strong {
      display: block;
      font-size: 2.8rem;
    }
  }

  .fieldset {
    margin: 40px 0;

    @include tablet {
      margin: 50px 0;
    }
  }

  p {
    max-width: 600px;
    margin: 20px auto;
  }

  .all-done-box + div {
    max-width: 600px;
    margin: 0 auto;
  }

  .owner-status {
    border-bottom: 1px solid var(--color-fieldset-border);
    margin: 0 0 20px 0;
    padding: 0 0 20px 0;

    &:last-child {
      border-bottom: 0;
    }

    &.has-additional-actions {
      margin: 0 0 20px 0;
      padding: 0 0 30px 0;
    }

    dl {
      display: flex;
      flex-wrap: wrap;
      margin: 5px 0 0 0;
      align-items: center;
    }

    dt {
      flex: 0 0 34px;
      margin: 4px 0;
    }

    dd {
      flex: 0 0 calc(100% - 34px);
      margin: 0;
    }

    // .contract-uploads-info {
    //   dl {
    //     margin: 14px 0 20px 0;

    //     @include tablet {
    //       margin: 14px 20px 20px 0;
    //     }
    //   }

    //   dt {
    //     flex: 0 0 75%;
    //     min-height: 32px;
    //     line-height: 32px;
    //   }

    //   dd {
    //     flex: 0 0 25%;
    //     min-height: 32px;
    //   }

    //   .upload-beacon {
    //     display: flex;
    //   }
    // }

    // .contract-uploads-actions {
    //   margin: 14px 0 0 0;

    //   .button {
    //     margin: 14px 0 20px 0;
    //   }
    // }
  }
}
