@import "../../ui/media.scss";

.data-list {
  display: flex;

  &.--small {
    font-size: 0.9em;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:first-child {
    margin-top: 0;
  }

  dd {
    font-weight: 600;
    margin-inline-start: 0;
  }

  dt,
  dd {
    word-break: break-word;
    margin-inline-start: 0;
  }

  &.--vertical {
    flex-direction: column;

    dt,
    dd {
      width: 100%;
      margin: 0;
    }

    dd {
      margin-bottom: 10px;
    }
  }

  &.--horizontal {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 20px;

    @include tablet {
      grid-template-columns: 1fr 1fr;
    }

    dt,
    dd {
      width: 100%;
    }

    dd {
      width: auto;
    }

    &.--left {
      dd {
        text-align: left;
      }
    }
    &.--right {
      dd {
        text-align: right;
        margin-left: auto;
      }
    }
    &.--center {
      dd {
        text-align: center;
        margin: 0 auto;
      }
    }
    &.--spaced {
      row-gap: 8px;
    }
  }
}
