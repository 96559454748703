@import "../../../ui/media.scss";

a.as-button,
.button {
  font-weight: 500;
  padding: 0 16px;
  height: 38px;
  background-color: var(--color-link);
  color: var(--color-white);
  // box-shadow: 0 4px 16px -7px var(--link-shadow);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  border: 0;
  transition: background-color 300ms ease, box-shadow 300ms ease;

  font-size: inherit;

  // font-size: 1.6rem;

  // @include tablet {
  //   font-size: 1.8rem;
  // }

  &.action {
    height: 38px;
    width: 38px;
    border-radius: 50%;
    padding: 0;

    .icon {
      margin: 0;
    }
  }

  &:hover,
  &:active,
  &:focus {
    background-color: var(--color-link-hover);
    color: var(--color-white);
    box-shadow: 0 4px 16px -7px transparent;
    text-decoration: none;
  }

  & + .button {
    margin: 0 0 0 10px;
  }

  & + .button.block,
  & + .as-button.block {
    margin: 10px 0 0 0;
  }

  &.block {
    display: flex;
    width: 100%;

    & + .button {
      margin: 10px 0 0 0;
    }
  }

  .icon {
    margin: 0 0 0 6px;
  }

  &.has-error,
  &.was-successful,
  &.is-pending,
  &.is-disabled {
    color: var(--color-white);
    box-shadow: none;
  }

  &.has-error {
    background-color: var(--color-red-heavy);
  }

  &.was-successful {
    background-color: var(--color-green);
  }

  &.is-pending {
    background-color: var(--color-disabled);
    cursor: wait;
  }

  &.is-disabled {
    background-color: var(--color-disabled);
    cursor: not-allowed;
  }

  &.ghost {
    background-color: var(--color-white);
    color: var(--color-button-ghost);
    border: 2px solid var(--color-button-ghost);

    &.has-error {
      color: var(--color-red-heavy);
      border: 2px solid var(--color-red-heavy);

      .icon {
        fill: var(--color-red-heavy);
      }
    }

    &.was-successful {
      color: var(--color-green);
      border: 2px solid var(--color-green);

      .icon {
        fill: var(--color-green);
      }
    }

    &.is-pending,
    &.is-disabled {
      color: var(--color-silver);
      border: 2px solid var(--color-silver);

      .icon {
        fill: var(--color-silver);
        & > span {
          background-color: var(--color-silver);
        }
      }
    }
  }

  &.ghost {
    .close {
      line {
        stroke: var(--color-link);
      }
    }
  }
}

button {
  &.as-link {
    text-decoration: underline;
    font-size: initial;
    transition: color 300ms ease;

    &:focus,
    &:hover,
    &:active {
      color: var(--link-shadow);
    }
  }
}
