@import "../../ui/media.scss";

.input {
  .input-label {
    font-size: 1.5rem;
    transition: color 300ms ease;
    padding: 2px 10px;
    font-weight: 500;
    line-height: 1.4;

    @include tablet {
      padding: 2px 10px 6px 10px;
      font-size: 1.7rem;
    }
  }

  .input-frame {
    position: relative;
  }

  select,
  input,
  textarea,
  ::placeholder {
    font-family: "Zilla Slab", system-ui, -apple-system, BlinkMacSystemFont,
      "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
      "Droid Sans", "Helvetica Neue", sans-serif;
  }

  select,
  input,
  textarea {
    appearance: none;
    line-height: var(--height-interaction);
    border-radius: 5px;
    display: inline-block;
    width: 100%;
    padding: 0 46px 0 9px;
    color: var(--color-text);
    font-size: 1.6rem;
    border: 1px solid var(--color-ghost);
    background-color: var(--color-white);

    @include tablet {
      font-size: 1.8rem;
      line-height: var(--height-interaction-tablet);
    }

    &:focus,
    &:active,
    &:hover {
      border: 1px solid var(--color-link);
    }
  }

  select,
  input {
    height: var(--height-interaction);
    @include tablet {
      height: var(--height-interaction-tablet);
    }
  }
  ::placeholder {
    font-style: italic;
    color: var(--color-text-passive);
  }

  .input-messages {
    line-height: 1.3;
    position: relative;
    margin: 4px 0 0 10px;
    font-size: 1.5rem;
    font-style: italic;
    color: var(--color-text-passive);
    min-height: 20px;

    @include tablet {
      font-size: 1.7rem;
      min-height: 22px;
    }
  }

  &.compact {
    .input-messages {
      display: none;
    }
  }

  .input-message,
  .input-hint {
    opacity: 1;
  }

  .input-message {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
  }

  &:hover,
  &:focus-within {
    .input-messages,
    .input-label {
      color: var(--color-link);
    }

    select,
    input {
      border: 1px solid var(--color-link);
    }
  }

  &.was-successful,
  &.has-error,
  &.is-disabled,
  &.is-pending {
    .input-hint {
      opacity: 0;
    }

    .input-message {
      opacity: 1;
    }
  }

  &.was-successful {
    .input-messages,
    .input-label {
      color: var(--color-text-success);
    }

    select,
    input {
      border: 1px solid var(--color-green);
    }
  }

  &.has-error {
    .input-messages,
    .input-label {
      color: var(--color-text-error);
    }

    select,
    input {
      border: 1px solid var(--color-text-error);
    }
  }

  &.is-pending,
  &.is-disabled {
    cursor: not-allowed;

    .input-messages,
    .input-label {
      color: var(--color-text-passive);
    }

    .checkbox-button,
    .radio-button,
    select,
    input,
    textarea {
      border: 1px solid var(--color-progress-border);
      border: 1px dashed var(--color-slate);
      cursor: not-allowed;
      color: var(--color-text-passive);
    }
  }

  &.is-pending {
    cursor: wait;

    .checkbox-button,
    .radio-button,
    select,
    input {
      cursor: wait;
    }
  }
}

.input {
  .input-frame {
    position: relative;
  }

  .icon {
    position: absolute;
    width: 28px;
    height: 28px;
    right: 5px;
    top: 5px;
    opacity: 0;
    // transition: opacity 300ms ease;

    @include tablet {
      right: 7px;
      top: 7px;
    }
  }

  .icon-error {
    fill: var(--color-text-error);
  }

  .icon-checkmark {
    fill: var(--color-green);
  }

  .icon-disabled {
    fill: var(--color-silver);
  }

  &.has-error {
    .icon-error {
      opacity: 1;
    }
  }

  &.was-successful {
    .icon-checkmark {
      opacity: 1;
    }
  }

  &.is-pending {
    .icon-pending {
      opacity: 1;
    }
  }

  &.is-disabled {
    .icon-disabled {
      opacity: 1;
    }
  }
}

.input-dropdown {
  .expand {
    opacity: 1;
  }
}

.input-dropdown {
  .expand-chevron {
    fill: var(--color-input-border);
  }

  &:hover,
  &:focus-within {
    .expand-chevron {
      fill: var(--color-link);
    }
  }

  &.has-error,
  &.was-successful,
  &.is-pending,
  &.is-disabled {
    .expand {
      opacity: 0;
    }
  }
}

input[type="checkbox"],
input[type="radio"] {
  position: absolute;
  height: 0;
  width: 0;
  opacity: 0;
}
