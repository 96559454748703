a,
.link,
.as-link {
  color: var(--color-link);
  font-weight: 500;
  text-decoration: none;
  vertical-align: baseline;

  &.block {
    display: block;
  }

  &.is-disabled {
    pointer-events: none;
  }

  &:hover,
  &:active,
  &:focus {
    color: var(--color-link-hovered);
    text-decoration: underline;
  }

  .icon {
    width: 20px;
    fill: var(--color-link);
    vertical-align: middle;
    margin: -2px 5px 0 0;
  }

  &.postfix {
    .icon {
      width: 1.3em;
    }
  }

  .icon-external {
    fill: none;
    stroke: var(--color-link);
    width: 16px;
    stroke-width: 2px;
  }

  .download-icon {
    font-size: 1.2em;
    margin-left: 0.5em;
  }
}

.external-link {
  line-height: normal;
  align-items: center;
}
