.icon_section {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 8px;
  padding-bottom: 18px;
}

.intro_text {
  padding-bottom: 24px;
}

.document_list {
  margin-bottom: 20px;
}

.list_item {
  padding: var(--p-content);
  margin-bottom: 10px;
  border-radius: 10px;
  background-color: var(--color-snow);
}

.uploaded {
  color: var(--color-success);
  fill: var(--color-success);
  background-color: var(--color-success-light);
  border-color: var(--color-success);
}

.header {
  display: flex;
  align-items: center;
  gap: var(--p-content);
}

.icon {
  margin-left: auto;
}
