.device-selection {
  width: 100%;
  margin: 0 auto;
  margin-top: 20vh;
  padding: 0 0 100px 0;

  .device-card {
    display: flex;
    flex-direction: column;
  }

  .divider {
    display: flex;
    align-items: center;
    margin: 26px 0 0 0;
    font-weight: bold;

    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 3px;
      background-color: #e0e0e0;
      margin: 10px 6px;
    }

    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 3px;
      background-color: #e0e0e0;
      margin: 10px 6px;
    }
  }

  .qr-code-wrapper {
    display: flex;
    justify-content: center;
  }

  .back-button {
    margin-top: 32px;
  }

  .text-input {
    & + .error-message,
    & + .success-message {
      margin-top: -8px;
    }
  }

  .success-message,
  .error-message {
    margin: 8px 0;
    color: var(--color-text-success);
    display: block;
  }

  .error-message {
    color: var(--color-text-error);
  }
}
