@import "../../../../ui/media.scss";
.store {
  padding-bottom: 20px;
  b {
    display: block;
    margin: 20px 0 5px 0;
  }

  .terminal {
    display: flex;
    justify-content: space-between;
  }

  .terminal {
    max-width: 450px;
  }

  .terminal-closure,
  .terminal-reservation,
  .terminal-tips,
  .terminal-name {
    flex: 0 0 90px;

    @include landscape {
      flex: 0 0 150px;
    }
  }

  .terminal-closure {
    flex: 1 0 120px;
    text-align: center;

    @include landscape {
      flex: 0 0 150px;
    }
  }

  .terminal-tips {
    flex: 0 0 51px;
    text-align: center;

    @include landscape {
      flex: 0 0 80px;
    }
  }

  .terminal-reservation {
    flex: 0 0 160px;
    text-align: center;
    .beacon {
      display: inline-block;
    }
  }

  .terminal-tips {
    .beacon {
      display: inline-block;
    }
  }
}

.contract-package {
  .pricing {
    i {
      font-weight: 300;
    }

    .disclaimer {
      color: var(--color-text-passive);
    }
  }
}
