@import "../../../ui/media.scss";

.signatures {
  .signature-header {
    font-weight: 300;
    margin: 0 0 8px 16px;

    @include landscape {
      margin: 0 0 12px 23px;
    }
  }

  .signature-wrapper {
    display: flex;
    flex-wrap: wrap;

    .signatory {
      width: 100%;
      // max-width: calc(1024px / 3);
      padding: 0 16px 32px 16px;

      @include tablet {
        max-width: 50%;
      }

      .signing-action {
        background-color: hsla(170, 48%, 51%, 0.3);
        padding: 12px 8px;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        transition: background-color 0.2s ease-in-out, padding 0.2s ease-in-out;

        span {
          border-bottom: 2px solid var(--color-button);
          transition: padding 0.2s ease-in-out;
        }

        &:hover {
          background-color: hsla(170, 48%, 51%, 0.5);
          padding-bottom: 10px;

          span {
            padding-bottom: 2px;
          }
        }
      }

      .signing-pending {
        background-color: hsla(59, 83%, 53%, 0.356);
        padding: 12px 8px;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        font-weight: 600;
      }

      .signee-name {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        margin: 0 0 8px 0;
        padding-top: 2px;
        border-top: 2px solid #8a8a8a;
      }

      img {
        display: block;
        max-width: 100%;
        background-color: #fff;
      }
    }
  }
}
