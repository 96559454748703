@import "../../../../ui/media.scss";

$box-border-radius: 10px;

.content {
  position: relative;
  overflow: hidden;
  background-color: #e1f1f9;
  border-radius: $box-border-radius;
}

.header {
  font-weight: 600;
  padding: 16px var(--p-content);
  border-bottom: 1px solid rgb(207, 227, 236);
}

.pack {
  padding: var(--p-content);
}

.table {
  border-bottom: 1px solid rgb(207, 227, 236);
  padding-bottom: var(--padding);
}

.terminal {
  position: relative;
  padding-top: 16px;
}

.error {
  color: var(--color-red-heavy);
  font-weight: 500;
  background-color: var(--color-red-light);
  padding: 14px;
  border-radius: 8px;
}

// .line {
//   position: absolute;
//   height: 200px;
//   width: 200px;
//   background-color: #45beaa;

//   top: -100px;
//   right: -100px;

//   border-radius: 50%;
//   // border-radius: $box-border-radius $box-border-radius 0 0;
// }

// .line_2 {
//   position: absolute;
//   height: 150px;
//   width: 150px;
//   background-color: #41b4d2;
//   z-index: 0;

//   bottom: -75px;
//   left: -75px;

//   border-radius: 50%;
//   // border-radius: $box-border-radius $box-border-radius 0 0;
// }
