@import "../../ui/media.scss";

.dialog-wrapper {
  pointer-events: none;
  transition: opacity 300ms ease;
  opacity: 0;
  position: relative;
  z-index: 4001;

  dialog {
    margin: 0;
    padding: 0;
  }

  &.is-active {
    pointer-events: all;
    opacity: 1;
  }

  &.has-error {
    .overlay {
      border: 2px solid var(--color-delete-text);
    }

    strong:first-child {
      color: var(--color-delete-text);
    }
  }

  .overlay {
    z-index: 200;
    width: calc(100vw - 32px);
    background-color: white;
    position: fixed;
    top: 20px;
    left: 16px;
    border: 2px solid var(--color-link);
    border-radius: 6px;
    min-height: 100px;
    box-shadow: var(--color-button-shadow);

    @include landscape {
      width: 430px;
      left: 50vw;
      margin: 0 0 0 -215px;
    }

    @include tablet {
      width: 600px;
      left: 50vw;
      margin: 0 0 0 -300px;
      top: 50px;
    }
  }

  .overlay-body {
    max-height: calc(100vh - 90px);
    overflow-y: auto;
    padding: 20px 16px 20px 16px;

    @include landscape {
      padding: 20px 25px 20px 25px;
    }

    @include tablet {
      max-height: calc(100vh - 100px);
    }

    .status-boxes {
      margin: 5px 0 0 8px;
    }
  }

  .overlay-close {
    position: absolute;
    right: -15px;
    top: -15px;
    background-color: var(--color-bg);

    @include landscape {
      right: -17px;
      top: -17px;
    }
  }
}

#overlay-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: var(--z-dialog-backdrop);
}
