@import "../../../../ui/media.scss";

.account-selection {
  .available-klarna-accounts {
    margin: 0 0 90px 0;

    @include tablet {
      margin: 0 0 130px 0;
    }
  }

  .klarna-account {
    position: relative;
    line-height: 1.4;
    border: 1px solid var(--color-fieldset-border);
    background-color: var(--color-white);
    transition: background-color 300ms ease;
    // border-radius: 25px;
    padding: 1px;
    margin: -1px 0 0 0;
    pointer-events: none;

    &.available:focus,
    &.available:active,
    &.available:hover {
      @include tablet {
        background-color: #f1fffd;
      }
    }

    .button {
      margin: 10px 0 4px 0;
      width: 100%;

      @include tablet {
        margin: 16px 0 6px 0;
      }
    }

    &:first-child {
      margin: 0;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }

    &:last-child {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }

    &.selected {
      border: 2px solid var(--link-hovered);
      padding: 0;
      z-index: 2;
      background-color: #f1fffd;
    }

    .klarna-account-inner {
      // flex: 0 0 calc(100% - 42px);
      // width: calc(100% - 42px);
      display: flex;
      padding: 10px 15px;
      overflow: hidden;
      line-height: 1.7;
      color: var(--color-text);
      text-align: left;
      max-width: 100%;
      width: 100%;

      align-items: center;

      .radio {
        margin-right: 15px;
      }

      ul {
        // opacity: 0.6;

        flex: 1 0 calc(100% - 40px);
        max-width: calc(100% - 40px);
      }
    }

    dt {
      display: none;
      @include tablet {
        display: inline-block;
      }
    }

    .truncate {
      display: block;
    }

    li {
      margin: 0;
      font-size: 1.5rem;
      line-height: 1.5;
    }

    &.available {
      pointer-events: all;
      b {
        font-weight: 700;
      }
    }

    &.is-disabled {
      .radio-unchecked {
        stroke: var(--color-fieldset-border);
      }

      b {
        opacity: 0.6;
      }
    }
  }

  .klarna-account-info {
    color: var(--color-red-heavy);
    font-size: 0.8em;
    // font-weight: 500;
    line-height: 1.5;
    // margin: 6px 0;
  }

  .error-box {
    font-weight: 500;
  }

  .next-button {
    width: 100%;
    margin: 0 0 30px 0;
  }

  .klarna-cant-find-account {
    font-size: var(--font-size-small);
    font-weight: 500;
    line-height: 1.5;
    margin-bottom: 10px;
    color: var(--color-text-passive);
  }
}
