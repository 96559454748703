.status-boxes {
  position: relative;

  line-height: 1.5;

  .status-box {
    transition: opacity 300ms ease;
    margin: 12px 0;
    width: 100%;
    display: none;
  }

  .default-box {
    display: flex;
    opacity: 1;
  }

  &.was-successful,
  &.has-required-information {
    .success-box {
      display: flex;
      opacity: 1;
    }

    .default-box {
      display: none;
      opacity: 0;
    }
  }

  &.has-error,
  &.has-missing-information {
    .error-box {
      display: flex;
      opacity: 1;
    }

    .default-box {
      opacity: 0;
    }
  }

  &.has-partial-information {
    .warning-box {
      display: flex;
      opacity: 1;
    }

    .default-box {
      opacity: 0;
    }
  }
}

.status-box.placeholder,
.status-box.relative {
  position: relative;
  min-height: 50px;
}
