@import "../../ui/media.scss";

.document-upload-page {
  .child-wrapper {
    max-width: 1000px;
    margin: 60px auto;

    @include tablet {
      margin: 100px auto;
    }
  }

  ul {
    margin: 50px 0 80px 0;
  }

  .todo-list-additional {
    display: inline-block;
    position: relative;
    font-size: 1.2rem;
    color: var(--color-ghost);
    line-height: 1.4;

    @include tablet {
      font-size: 1.4rem;
    }
  }

  li .beacon {
    position: absolute;
    right: -26px;
    top: 0;
  }
}
