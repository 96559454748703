.document-upload-phone {
  .video-document-overlay {
    box-shadow: 0px 0px 0px 5000px rgba(255, 255, 255, 0.3);
    border: 8px solid rgba(255, 255, 255, 0.7);
    overflow: hidden;
    position: absolute;
    top: 30%;
    left: 8%;
    height: 35%;
    width: 84%;
    border-radius: 25px;
  }
}

.document-confirm-phone {
  .columns {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
}

.document-upload-phone-buttons {
  flex: 1;
}
