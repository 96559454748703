.icpp {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.general_content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.label {
  width: 175px;
  color: var(--color-label);
}

.value {
  font-weight: 500;
}
