@import "../../../ui/media.scss";

.floating-action-button {
  position: fixed;
  bottom: 30px;
  right: 50%;
  transform: translateX(50%);
  background-color: var(--link);
  color: var(--color-white);
  box-shadow: 0 4px 16px -7px var(--link-shadow);
  border-radius: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  display: none;

  @include tablet {
    bottom: 60px;
  }
}
