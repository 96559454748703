@import "../../../../ui/media.scss";
$border-color: var(--color-snow-heavy);

.root {
  border-radius: 10px;
  overflow: hidden;
  background-color: var(--color-snow);
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 16px var(--p-content);
}

.list {
  border-top: 1px solid $border-color;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: var(--p-content);
}

.row {
  display: flex;
  justify-content: space-between;
  line-height: 1.5;
  margin-bottom: 10px;

  dd {
    margin: 0 !important;
    font-weight: 600;
  }

  dt {
    max-width: 200px;
    @include tablet {
      max-width: 300px;
    }
  }
}

.label {
  font-weight: 500;
}

.i {
  font-size: var(--font-size-small);
  font-style: italic;
}
