@import "../../../ui/media.scss";

.text-input {
  margin-bottom: 12px;

  input[type="text"] {
    appearance: none;
    border: 1px solid var(--color-input-border);
    line-height: var(--height-interaction);
    border-radius: 5px;
    display: inline-block;
    width: 100%;
    padding: 0 46px 0 9px;
    color: var(--color-text);
    background-color: var(--color-input-bg);
    font-size: 1.6rem;
  }

  label {
    font-size: 1.5rem;
    transition: color 300ms ease;
    // padding: 2px 10px;
    font-weight: 500;
    line-height: 1.4;

    padding: 0 0 4px 0;
    min-width: 0;
    flex: 1 0 auto;
    width: 0;
  }
}
