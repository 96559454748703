@import "../../../../ui/media.scss";

.identification-selection {
  width: 100%;
  margin: 20vh auto;

  .device-card {
    display: flex;
    flex-direction: column;
  }

  .divider {
    display: flex;
    align-items: center;
    margin: 26px 0 0 0;
    font-weight: bold;

    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 3px;
      background-color: #e0e0e0;
      margin: 10px 6px;
    }

    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 3px;
      background-color: #e0e0e0;
      margin: 10px 6px;
    }
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: auto;
    gap: 16px;
    padding: 0 0 20px 0;

    @include landscape {
      gap: 35px;
    }

    .grid-text {
      grid-column: 1 / 3;
      grid-row: 1;
    }

    .id-front,
    .id-back {
      border: 1px solid var(--color-disabled);
      border-radius: 5px;
      overflow: hidden;
    }

    .id-images {
      grid-column: 3 / 4;
      grid-row: 1;

      .id-front {
        margin: 0 0 10px 0;
      }

      @include landscape {
        grid-row: 1 / 3;
      }
    }

    .button {
      grid-column: 1 / 4;
      grid-row: 2;

      @include landscape {
        grid-column: 1 / 3;
        grid-row: 2 / 3;
        align-self: flex-end;
      }
    }
  }

  // .id-wrapper {
  //   display: flex;
  //   flex-wrap: nowrap;
  //   justify-content: space-between;
  //   align-items: flex-start;
  //   margin: 0 0 30px 0;

  //   .image {
  //     background-color: transparent;
  //   }

  //   .id-front {
  //     margin: 0 0 10px 0;
  //     border: 1px solid var(--color-disabled);
  //     border-radius: 5px;
  //     overflow: hidden;
  //   }

  //   .id-back {
  //     border: 1px solid var(--color-disabled);
  //     border-radius: 5px;
  //     overflow: hidden;
  //   }

  //   p {
  //     width: 64%;
  //     flex: 0 0 64%;
  //   }

  //   & > div {
  //     width: 30%;
  //     flex: 0 0 30%;
  //   }
  // }
}
