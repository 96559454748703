.contract-sheet {
  .pdf-viewer {
    height: 100%;
    margin-top: 24px;
  }

  .contract-sheet-content {
    padding: 0 !important;
  }
}
