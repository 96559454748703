@import "../Base.scss";
@import "../../../ui/media.scss";

.checkbox-button,
.radio-button {
  position: relative;
  width: 100%;
  display: block;
  height: var(--height-interaction);
  line-height: calc(var(--height-interaction) - 2px);
  font-size: 1.6rem;
  padding: 0 40px;
  z-index: 1;
  margin: -1px 0 0 0;
  cursor: pointer;
  border: 1px solid var(--color-ghost);
  background-color: var(--color-white);

  @include tablet {
    padding: 0 46px;
    font-size: 1.8rem;
    height: var(--height-interaction-tablet);
    line-height: calc(var(--height-interaction-tablet) - 2px);
  }

  &:hover,
  &:focus-within {
    border: 1px solid var(--color-link);
    z-index: 2;
  }

  .checkbox-label,
  .radio-label {
    pointer-events: none;
    white-space: nowrap;
  }

  &.is-first {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    margin: 0;
  }

  &.is-last {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .checkbox,
  .radio {
    position: absolute;
    top: 9px;
    left: 9px;
  }

  .checkbox {
    top: 8px;
  }
}
