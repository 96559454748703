.root {
}

.header {
  font-weight: 600;
  border-bottom: 1px solid #d4e3eb;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.li {
  display: grid;
  grid-template-columns: minmax(125px, 1fr) 50px 110px;
  gap: 10px;

  .type {
    color: var(--color-label);
  }

  .count {
    text-align: center;
    color: var(--color-label);
  }

  .subcost {
    // color: var(--color-label);
    font-weight: 500;
    text-align: right;
  }
}

.total_row {
  padding-top: var(--p-content);

  display: flex;
  justify-content: space-between;

  .total {
    font-weight: 500;
  }
  .total_price {
    font-weight: 600;
  }
}

.error {
  font-weight: 500;
  background-color: var(--color-red-light);
  border: 1px solid var(--color-red-heavy);
  color: var(--color-red-extra-heavy);

  text-align: center;

  padding: 20px;
  border-radius: 10px;
}

.error_color {
  color: var(--color-red-heavy);
}
