@import "../../../../ui/media.scss";

.transaction-fees {
  .fee-description {
    font-size: 0.9em;
    font-style: italic;
    color: var(--color-text-passive);
  }

  .brand-list {
    display: flex;
    // align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;

    li {
      .provider-logo {
        display: block;
        margin: 0;
      }
      img {
        margin: 0;
        display: inline;
      }
    }
  }

  .transaction-fees-row {
    background-color: var(--color-snow);
    border-radius: 8px;
    padding: var(--p-content);
    margin: 12px 0;
  }

  .transaction-fee {
    display: flex;
    flex-direction: column;

    &-label {
      font-weight: 500;
      color: var(--color-label);
    }

    &-value {
      padding: 0;
      font-weight: 600;
      color: var(--color-text);
    }
  }

  .fee-columns {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 18px;

    @include landscape {
      grid-template-columns: repeat(2, 1fr);
    }

    @include tablet {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}
