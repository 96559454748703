@import "../../../../ui/media.scss";

.root {
}

.sheetContent {
  //   background-color: var(--color-snow);
}

.title {
  text-align: center;
  font-weight: 500;

  padding: 12px;
  border-bottom: 1px solid var(--color-snow-heavy);
  //   background-color: var(--color-white);
  margin: 0;
}

.content {
  padding: 12px;
  max-width: calc($tablet - 100px);
  margin: 0 auto;
}

.uploaded {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 500;
  padding: 6px 16px;
  border-radius: 20px;
  color: #e69900;
  fill: #e69900;
  background-color: #fdf5c7;

  svg {
    margin-right: 6px;
  }
}

.allUploaded {
  background-color: var(--color-success-light);
  color: var(--color-success);
  fill: var(--color-success);
}

.documents {
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 12px;
}

.doc {
  width: 100%;
}
