@import "../../../ui/media.scss";

.sales-contact {
  max-width: var(--max-width-content);
  margin: 0 auto;

  .sales-contact-flex {
    display: flex;
    flex-wrap: wrap;
    margin-top: 3px;
    margin-bottom: 0px;

    dt {
      flex: 0 0 100px;
      margin: 2px 0;
      font-size: inherit;
    }

    dd {
      flex: 0 0 calc(100% - 100px);
      margin: 2px 0;
    }
  }

  .sales-rep-info {
  }
}
