@import "./media.scss";

.relative {
  position: relative;
}

.tablet-flex {
  @include tablet {
    display: flex;
    justify-content: space-between;
  }
}

.landscape-columns {
  @include landscape {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  & > div {
    @include landscape {
      flex: 0 0 calc(50% - 20px);
    }
  }
}

.tablet-columns {
  @include tablet {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  & > div {
    @include tablet {
      flex: 0 0 calc(50% - 20px);
    }
  }
}

.external-link {
  display: inline-flex;

  .icon {
    margin: 0 0 0 7px;
    width: 20px;
  }

  .icon-external {
    fill: none;
    stroke: var(--color-link);
    stroke-width: 2px;
  }
}

.logo {
  max-width: 225px;
}

.m-none {
  margin: 0;
}

.m-top-10 {
  margin: 10px 0 0 0;
}

.m-top-20 {
  margin: 20px 0 0 0;
}

.m-top-30 {
  margin: 30px 0 0 0;
}

.m-top-40 {
  margin: 40px 0 0 0;
}

.m-bottom-5 {
  margin: 0 0 5px 0;
}

.m-bottom-10 {
  margin: 0 0 10px 0;
}

.m-bottom-20 {
  margin: 0 0 20px 0;
}

.m-bottom-30 {
  margin: 0 0 30px 0;
}

.m-bottom-40 {
  margin: 0 0 40px 0;
}

.m-bottom-1em {
  margin: 0 0 1em 0;
}

.m-bottom-2em {
  margin: 0 0 2em 0;
}

.m-y-1em {
  margin: 1em 0;
}

.capitalized {
  text-transform: capitalize;
}

.truncate {
  @include truncate;
}

.text-passive {
  color: var(--color-text-passive);
}

.color-success {
  color: var(--color-success);
}
