.spinner-motion {
  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    overflow: visible;
  }

  span {
    font-weight: 600;
    font-size: 1.2rem;
    margin-top: 1rem;
    color: var(--color-link);
  }
}
