@import "../../ui/media.scss";

.fieldset {
  border: 1px solid var(--color-fieldset-border);
  border-radius: 5px;
  position: relative;
  padding: 26px 10px;

  @include landscape {
    padding: 36px 16px;
  }

  @include tablet {
    padding: 36px 25px;
  }

  & > .fieldset-header {
    position: absolute;
    top: -15px;
    background-color: var(--color-bg);
    padding: 0 10px;
    font-weight: 300;
    font-size: 1.6rem;
    margin: 0;
    max-width: 85%;
    overflow: hidden;
    text-overflow: clip;
    text-overflow: ellipsis;
    white-space: nowrap;

    @include tablet {
      top: -19px;
      font-size: 2rem;
    }
  }

  &.with-columns {
    .fieldset-inner {
      @include tablet {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
    }
  }

  .column {
    @include tablet {
      flex: 0 0 calc(50% - 25px);
      max-width: calc(50% - 25px);
    }
  }

  .full {
    @include tablet {
      flex: 0 0 100%;
      margin: 0 0 10px 0;
      max-width: 100%;
    }
  }

  .divider {
    margin: 20px 0;
  }
}
