.status-box {
  min-height: 50px;

  display: flex;
  gap: 8px;
  border: 1px solid var(--color-snow-heavy);
  border-radius: 5px;
  padding: 12px;
  font-weight: 500;

  align-items: center;

  &.default-box {
    .status-box-message {
      padding: 7px 0 0 0;
    }
  }

  .status-box-icon {
    height: 42px;
    width: 42px;
    border-radius: 21px;
    padding: 6px;
  }

  .icon {
    width: 30px;
    height: 30px;
    display: none;
  }

  &.error-box {
    background-color: rgba(246, 234, 236, 1);
    border-color: #c04647;

    .status-box-icon {
      // box-shadow: 0 0 14px -6px #882323;
    }

    .icon-error-outline {
      fill: #c04647;
      display: block;
    }
  }

  &.success-box {
    background-color: #e5f2f4;
    border-color: var(--color-link);
    // .status-box-icon {
    //   box-shadow: 0 0 14px -6px #27624e;
    // }

    .icon-checkmark {
      fill: var(--color-link);
      display: block;
    }
  }

  &.warning-box {
    background-color: rgba(255, 246, 213, 1);
    border-color: #e69900;
    .status-box-icon {
      // box-shadow: 0 0 14px -6px #74510b;
    }

    .icon-warning-outline {
      fill: #e69900;
      display: block;
    }
  }
}
