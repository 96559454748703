@import "../../ui/media.scss";

.content {
  margin: 0 auto;
}

.button {
  gap: 0.5em;
  margin-top: 12px;

  @include tablet {
    margin-top: 20px;
  }
}

.icon {
  font-size: 1.2em;
}

.buttons {
  display: flex;
  flex-direction: column;

  gap: 8px;
}
